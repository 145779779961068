import React, { useEffect, useState } from "react";
import LargeCVEntry from "../components/LargeCVEntry";

const Projects = () => {
  const [leetcodeData, setLeetcodeData] = useState({
    totalEasy: "-",
    easySolved: "-",
    totalMedium: "-",
    mediumSolved: "-",
    totalHard: "-",
    hardSolved: "-",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://leetcode-stats-api.herokuapp.com/jlscheerer",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLeetcodeData(data);
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="background">
      <div className="container items-center flex-col flex">
        <h1 className="page-heading">Projects</h1>
        <LargeCVEntry
          title="Time Series Data Mining using Matrix Profiles on FPGAs"
          subtitle="C++, OpenCL, Xilinx Vitis™"
          time=""
          location=""
          icon="img/matrix.png"
        >
          Development of a systolic array-based design to compute the Matrix
          Profile efficiently on Xilinx FPGAs using C++, OpenCL and Vitis™
          High-Level Synthesis for Xilinx FPGAs. The solution scales well with
          both memory as well as compute resources.
        </LargeCVEntry>
        <LargeCVEntry
          title="RISC-V Processor (incl. Instruction Level Pipelining)"
          subtitle="C, Java, VHDL"
          time=""
          location=""
          icon="img/riscv.png"
        >
          Implementation of a processor-core compliant with the RISC-V
          specification, optimized with an instruction level pipeline. Over the
          span of two semesters, our team of six students realized the project
          with the help of the circuit description language VHDL on a FPGA
          board.
        </LargeCVEntry>
        <LargeCVEntry
          title="Cobold Programming Language"
          subtitle="C++, LLVM"
          time=""
          location=""
          icon="img/LLVM.png"
        >
          Development of my own strongly typed, low-level, general purpose
          programming language in C++. Cobold has performance matching C++ using
          LLVM, with low-level access to bits and addresses.
        </LargeCVEntry>
        <LargeCVEntry
          title={
            <span>
              leetcode.com (
              <a href="https://leetcode.com/jlscheerer/">@jlscheerer</a>)
            </span>
          }
          subtitle="C++, Java, Python3"
          time=""
          location=""
          icon="img/leetcode.png"
        >
          Aiming to solve all <a href="https://leetcode.com">leetcode.com</a> (
          <i>competitive programming</i>) problems. <br />
          Current progress:{" "}
          <span style={{ color: "#01b8a2" }}>
            {leetcodeData["easySolved"]}/{leetcodeData["totalEasy"]} Easy
          </span>{" "}
          |{" "}
          <span style={{ color: "#ffc01e" }}>
            {leetcodeData["mediumSolved"]}/{leetcodeData["totalMedium"]} Medium
          </span>{" "}
          |{" "}
          <span style={{ color: "#ef4642" }}>
            {leetcodeData["hardSolved"]}/{leetcodeData["totalHard"]} Hard
          </span>
        </LargeCVEntry>
      </div>
    </div>
  );
};

export default Projects;
